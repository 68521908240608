import { IHeaderItem } from "@models";
import { getFontSize } from "@utils";
import { useState } from "react";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";
import { ItemHeaderContainer, ItemHeaderContents } from "../screens";
import { Text } from "../Text";

interface ListItemHeaderProps {
  items: IHeaderItem[];
  tableRef: React.RefObject<HTMLTableElement>;
}

export function ListItemHeader({ items, tableRef }: ListItemHeaderProps) {
  const [sorting, setSorting] = useState({ name: "", dir: "asc" });

  const sortTable = (n: number, dir: string) => {
    let table, rows, switching, o, x, y, shouldSwitch;

    if (tableRef.current === null) return;

    table = tableRef.current;
    switching = true;

    while (switching) {
      switching = false;
      rows = table.getElementsByTagName("tr");

      if (rows.length - 2 > 0) {
        for (o = 1; o < rows.length - 1; o++) {
          shouldSwitch = false;

          x = rows[o].getElementsByTagName("td")[n];
          y = rows[o + 1].getElementsByTagName("td")[n];

          if (dir === "asc") {
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          } else if (dir === "desc") {
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          }
        }

        if (shouldSwitch) {
          if (rows[o].parentNode !== null) {
            rows[o].parentNode?.insertBefore(rows[o + 1], rows[o]);
            switching = true;
          }
        }
      } else break;
    }
  };

  const HeaderItem = ({
    item,
    index,
  }: {
    item: IHeaderItem;
    index: number;
  }) => {
    return (
      <ItemHeaderContents
        flex={item.flex}
        style={{ minWidth: item?.width }}
        sortAble={item.sortAble}
        onClick={() => {
          if (item.sortAble) {
            sortTable(index, sorting.dir);
            return setSorting((prev) => {
              return {
                name: item.title,
                dir: prev.dir === "asc" ? "desc" : "asc",
              };
            });
          } else return;
        }}
      >
        <Text color={"#000000"} size="md" weight="bold">
          {item.title}
        </Text>
        {item.icon && (
          <img src={item.icon} alt="" width={"20px"} height={"20px"} />
        )}
        {item.sortAble &&
          sorting.name === item.title &&
          (sorting.dir === "asc" ? (
            <IoCaretUp size={getFontSize("md")} color={"#000000"} />
          ) : (
            <IoCaretDown size={getFontSize("md")} color={"#000000"} />
          ))}
      </ItemHeaderContents>
    );
  };

  return (
    <ItemHeaderContainer>
      {items.map((item, index) => {
        return <HeaderItem key={index.toString()} item={item} index={index} />;
      })}
    </ItemHeaderContainer>
  );
}
