import React, { useMemo } from "react";

import { IGenericItem } from "@models";
import { Button, Grid, Typography, styled } from "@mui/material";

const TypographyTitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: "bold",
  alignSelf: "center",
  marginRight: "15px",
});

const ButtonToggle = styled(Button)({
  mt: "4px",
  height: "35px",
  color: "#999999",
  alignSelf: "center",
  padding: "8px 12px",
  border: "1px #BBBBBB solid",
});

interface Props {
  title: string;
  defaultValue?: string;
  data: IGenericItem[];
  containerStyles?: React.CSSProperties;
  onChangeValue: (value: any) => void;
}

const FuncComponent: React.FC<Props> = ({
  data,
  title,
  containerStyles,
  defaultValue = "",
  onChangeValue,
}) => {
  const renderItems = useMemo(
    () => (
      <Grid style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {data.map((item) => (
          <ButtonToggle
            key={item.value}
            variant="outlined"
            style={
              item.value === defaultValue
                ? {
                    color: "black",
                    border: "1px black solid",
                  }
                : undefined
            }
            onClick={() => onChangeValue(item.value || "")}
          >
            {item.name}
          </ButtonToggle>
        ))}
      </Grid>
    ),
    [data, defaultValue, onChangeValue]
  );

  return (
    <Grid style={{ display: "flex", flexDirection: "row", ...containerStyles }}>
      <TypographyTitle sx={{ paddingBottom: 0 }}>{title}</TypographyTitle>
      {renderItems}
    </Grid>
  );
};

export default FuncComponent;
