import { useCallback } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import MonthPicker from "simple-react-month-picker";
import { Grid, Typography, styled } from "@mui/material";

import { MAIN_STYLES } from "@constants";
import i18n from "@utils/i18n";

const TypographyTitle = styled(Typography)({
  color: "#111111",
  fontWeight: "700",
  fontSize: "16px",
  alignSelf: "center",
  padding: "0 10px",
});

interface Props {
  onChange?: (
    startMonth: string | undefined,
    endMonth: string | undefined
  ) => void;
}

const FuncComponent: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const handleOnChange = useCallback(
    (values: string[]) => {
      if (values.length === 0) {
        onChange && onChange(undefined, undefined);
      } else {
        onChange &&
          onChange(
            values[0] === null
              ? undefined
              : moment(values[0]).format("YYYY-MM-DD"),
            values[1] === null
              ? undefined
              : moment(values[1]).format("YYYY-MM-DD")
          );
      }
    },
    [onChange]
  );

  return (
    <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <TypographyTitle sx={{ paddingBottom: 0 }}>
        {t("ota_detailed_analysis.period")}
      </TypographyTitle>
      <MonthPicker
        closeDelay={500}
        language={i18n.language}
        highlightCol={MAIN_STYLES.mainPurple}
        defaultTitleProps={t("write_review_page.entire_period")}
        onChange={handleOnChange}
      />
    </Grid>
  );
};

export default FuncComponent;
