import {  useContext } from "react";

import {
  Box,
  List,
  alpha,
  styled,
  Button,
  ListItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";

import { SidebarContext } from "../../../../contexts/SidebarContext";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: red;
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.customize.purple};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const SidebarMenu = () => {
  const { t } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <MenuWrapper>
      <List component="div" style={{ padding: "0 30px" }}>
        <SubMenuWrapper>
          <List component="div" style={{ padding: 0 }}>
            <ListItem component="div">
              <Button
                component={RouterLink}
                onClick={closeSidebar}
                to="/"
                style={{
                  padding: "15px",
                }}
              >
                {t("admin.호텔관리")}
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                component={RouterLink}
                onClick={closeSidebar}
                to="/user-management"
                style={{
                  padding: "15px",
                }}
              >
                {t("admin.고객관리")}
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                component={RouterLink}
                onClick={closeSidebar}
                to="/group-management"
                style={{
                  padding: "15px",
                }}
              >
                {t("admin.그룹관리")}
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                component={RouterLink}
                onClick={closeSidebar}
                to="/banner-management"
                style={{
                  padding: "15px",
                }}
              >
                {t("admin.배너관리")}
              </Button>
            </ListItem>
            {/* <ListItem component="div">
              <Button
                component={RouterLink}
                onClick={closeSidebar}
                to="/promotion-management"
                style={{
                  padding: "15px",
                }}
              >
                {t("admin.프로모션 관리")}
              </Button>
            </ListItem> */}
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
};

export default SidebarMenu;
