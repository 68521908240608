import { getFontSize, toPixelValue } from "@utils";

export const DEFAULT_PADDING = 12;
export const DEFAULT_HALF_PADDING = DEFAULT_PADDING * 0.5;
export const DEFAULT_DOUBLE_PADDING = DEFAULT_PADDING * 2;
export const DEFAULT_DOUBLE_HALF_PADDING = DEFAULT_HALF_PADDING * 0.5;
export const DEFAULT_LARGE_PADDING = DEFAULT_PADDING * 1.5;
export const HTTPS_HOST_NAME =
  typeof window !== "undefined" && window.location.host
    ? `https://${window.location.host}`
    : "";

export const DEFAULT_FONT_SIZE = getFontSize("sm");
export const DEFAULT_BORDER_RADIUS = 4;
export const SECOND_BORDER_RADIUS = 4;
export const DEFAULT_EDITOR_WIDTH = 500;

export const MAIN_STYLES = {
  primary: "#007AFF",
  background: "#EFEFF4",
  card: "#EFEFF4",
  text: "black",
  border: "rgba(60,60,67,0.29)",
  notification: "#FF3B30",

  label: "black",
  secondaryLabel: "rgba(60,60,67,0.8)",
  tertiaryLabel: "rgba(60,60,67,0.3)",
  quaternaryLabel: "rgba(60,60,67,0.18)",

  systemBackground: "#FFFFFF",
  secondarySystemBackground: "#EFEFF4",
  tertiarySystemBackground: "#FFFFFF",

  systemGroupedBackground: "#EFEFF4",
  secondarySystemGroupedBackground: "#FFFFFF",
  tertiarySystemGroupedBackground: "#EFEFF4",

  systemFill: "rgba(120,120,128,0.2)",
  secondarySystemFill: "rgba(120,120,128,0.16)",
  tertiarySystemFill: "rgba(120,120,128,0.12)",
  quaternarySystemFill: "rgba(120,120,128,0.07)",

  link: "#007AFF",
  placeholderText: "rgba(60,60,67,0.3)",
  separator: "rgba(60,60,67,0.29)",
  red: "#FF3B30",
  orange: "#FF9500",
  yellow: "#FFCC00",
  green: "#34C759",
  teal: "#5AC8FA",
  blue: "#007AFF",
  indigo: "#5856D6",
  purple: "#AF52DE",
  pink: "#FF2D55",
  grey1: "#8E8E93",
  grey2: "#AEAEB2",
  grey3: "#C7C7CC",
  grey4: "#D1D1D6",
  grey5: "#E5E5EA",
  grey6: "#F2F2F7",

  padding: toPixelValue(DEFAULT_PADDING),
  largePadding: toPixelValue(DEFAULT_LARGE_PADDING),
  doubleHalfPadding: toPixelValue(DEFAULT_DOUBLE_HALF_PADDING),
  halfPadding: toPixelValue(DEFAULT_HALF_PADDING),
  doublePadding: toPixelValue(DEFAULT_DOUBLE_PADDING),
  borderRadius: toPixelValue(DEFAULT_BORDER_RADIUS),
  fontSize: toPixelValue(DEFAULT_FONT_SIZE),
  secondBorderRadius: toPixelValue(SECOND_BORDER_RADIUS),
  colorScheme: "light",
  membershipBackground: "#FAF3FE",

  gray: "#CCCCCC",

  purple1: "#A212F5",
  purple2: "#AD00FF",
  purple3: "#FAF3FE",
  mainPurple: "#961BED",
  mainBlack: "#111111",
};
