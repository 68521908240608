import { lazy } from "react";
import { AdminSidebarLayout } from "@layouts";
import { RouteObject } from "react-router-dom";

const HotelManagementPage = lazy(() => import("@pages/admin/hotelManagement"));
const UserManagementPage = lazy(() => import("@pages/admin/userManagement"));
const GroupManagementPage = lazy(() => import("@pages/admin/groupManagement"));
const BannerManagementPage = lazy(
  () => import("@pages/admin/bannerManagement")
);
const PromotionManagementPage = lazy(
  () => import("@pages/admin/promotionManagement")
);
const DetailedHotelPage = lazy(() => import("@pages/admin/detailedHotel"));
const DetailedUserPage = lazy(() => import("@pages/admin/detailedUser"));
const HotelLogPage = lazy(() => import("@pages/admin/hotelLog"));
const AddHotelPage = lazy(() => import("@pages/admin/createHotel"));
const AddGroupPage = lazy(() => import("@pages/admin/addGroup"));
const DetailedGroupPage = lazy(() => import("@pages/admin/detailedGroup"));
const CreateBannerPage = lazy(() => import("@pages/admin/createBanner"));
const DetailedBannerPage = lazy(() => import("@pages/admin/detailedBanner"));

const AdminRouter: RouteObject[] = [
  {
    path: "/",
    element: <AdminSidebarLayout />,
    children: [
      {
        path: "/",
        element: <HotelManagementPage />,
      },
      {
        path: "/detailed-hotel/:hotelId",
        element: <DetailedHotelPage />,
      },
      {
        path: "/detailed-user/:userId",
        element: <DetailedUserPage />,
      },
      {
        path: "/detailed-hotel/:hotelId",
        element: <DetailedHotelPage />,
      },
      {
        path: "/hotel-log-info/:hotelId",
        element: <HotelLogPage />,
      },
      {
        path: "/add-new-hotel/:nextId",
        element: <AddHotelPage />,
      },
      {
        path: "/group-management/add-new-group",
        element: <AddGroupPage />,
      },
      {
        path: "/banner-management/add-new-banner",
        element: <CreateBannerPage />,
      },
      {
        path: "/banner-management/detailed-banner/:bannerId",
        element: <DetailedBannerPage />,
      },
      {
        path: "/user-management",
        element: <UserManagementPage />,
      },
      {
        path: "/group-management",
        element: <GroupManagementPage />,
      },
      {
        path: "/group-management/detailed-group/:groupId",
        element: <DetailedGroupPage />,
      },
      {
        path: "/banner-management",
        element: <BannerManagementPage />,
      },
      {
        path: "/promotion-management",
        element: <PromotionManagementPage />,
      },
    ],
  },
];

export default AdminRouter;
