import { useState, useContext, useEffect } from "react";

import {
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Collapse,
} from "@mui/material";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { USER_INFO } from "@states";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  magnifying_glass,
  magnifying_glass_active,
  map_pin,
  map_pin_fill,
  pencil_line,
  pencil_line_active,
} from "@assets";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: red;
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.customize.purple};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const SidebarMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  
  const user = useRecoilValue(USER_INFO);
  const { closeSidebar } = useContext(SidebarContext);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSubMenuVS, setOpenSubMenuVS] = useState(false);

  const handleSubMenuToggle = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleSubMenuToggleVS = () => {
    setOpenSubMenuVS(!openSubMenuVS);
  };

  const nowDate = moment();
//  console.log(nowDate)

//   const nowDate = moment("2024-10-28");
//   console.log(nowDate.isBetween("2024-10-27", "2024-11-01"));
//  console.log(!(user?.email=='help@heroworks.co.kr' && (nowDate.isBetween("2024-10-27", "2024-11-01"))));
  return (
    <MenuWrapper>
      <List component="div" style={{ padding: "0 30px" }}>
        <SubMenuWrapper>
          <List component="div" style={{ padding: 0 }}>          
          {!(user?.email=='help@heroworks.co.kr' && (nowDate.isBetween("2024-10-27", "2024-11-01"))) &&
            <ListItem component="div">
              <Button
                component={RouterLink}
                onClick={closeSidebar}
                to="/"
                startIcon={
                  currentPath === "/" ? (
                    <img src={pencil_line_active} alt="pencil-line-active" />
                  ) : (
                    <img src={pencil_line} alt="pencil-line" />
                  )
                }
                style={{
                  padding: "15px",
                }}
              >
                {t("sidebar_menu.write_review")}
              </Button>
            </ListItem>
        }
            <ListItem component="div">
              <Button
                disableRipple
                onClick={handleSubMenuToggle}
                startIcon={
                  currentPath === "/detailed-analysis" ||
                  currentPath === "/period-analysis" ? (
                    <img
                      src={magnifying_glass_active}
                      alt="magnifying-glass-active"
                    />
                  ) : (
                    <img src={magnifying_glass} alt="magnifying-glass" />
                  )
                }
                endIcon={
                  openSubMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />
                }
                style={{
                  padding: "15px",
                }}
              >
                {t("sidebar_menu.ota_review_analysis")}
              </Button>
            </ListItem>
            <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/detailed-analysis"
                  >
                    {t("sidebar_menu.detailed_analysis")}
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/period-analysis"
                  >
                    {t("sidebar_menu.analysis_by_period")}
                  </Button>
                </ListItem>
              </List>
            </Collapse>
            <ListItem component="div">
              <Button
                disableRipple
                onClick={handleSubMenuToggleVS}
                startIcon={
                  currentPath === "/detailed-analysis-by-period" ||
                  currentPath === "/analysis-by-period" ? (
                    <img src={map_pin_fill} alt="map-pin-fill" />
                  ) : (
                    <img src={map_pin} alt="map-pin" />
                  )
                }
                endIcon={
                  openSubMenuVS ? <ExpandMoreIcon /> : <ChevronRightIcon />
                }
                style={{
                  padding: "15px",
                }}
              >
                vs Market
              </Button>
            </ListItem>
            <Collapse in={openSubMenuVS} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/detailed-analysis-by-period"
                  >
                    {t("sidebar_menu.detailed_analysis")}
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/analysis-by-period"
                  >
                    {t("sidebar_menu.analysis_by_period")}
                  </Button>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
};

export default SidebarMenu;
