// GradientOpacityBox.tsx
import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

interface GradientOpacityBoxProps {
  children: ReactNode;
}

const GradientOpacityBox: React.FC<GradientOpacityBoxProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        width: 400,
        height: 100,
        position: "relative",
        p: 2,
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <Typography
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
          display: "block",
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 100%)",
            pointerEvents: "none",
          },
        }}
        component="div"
      >
        {children}
      </Typography>
    </Box>
  );
};

export default GradientOpacityBox;
