import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import i18n from "@utils/i18n";
import useClickOutside from "@hooks/useClickOutside";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControlLabel, Radio, Typography, styled } from "@mui/material";

import {
  LangItem,
  SelectContainer,
  ContentContainer,
  SelectOptionInner,
  SelectOptionContainer,
} from "./styles";

import { IWebLanguage } from "@models";
import { MAIN_STYLES } from "@constants";
import { View } from "@components/screens";

const Title = styled(Typography)({
  color: "#111111",
  fontSize: "16px",
  fontWeight: "700",
  padding: "0 10px",
  alignSelf: "center",
  fontFamily: "Pretendard Variable, monospace",
});

const Content = styled(Typography)({
  color: "#111111",
  fontSize: "16px",
  fontWeight: "700",
  fontFamily: "Pretendard Variable, monospace",
});

interface Props {
  language?: {
    name: string;
    code: string;
  };
}

const LANGUAGE_CODES = [
  {
    name: "Korean",
    code: "ko",
  },
  {
    name: "English",
    code: "en",
  },
  {
    name: "Japanese",
    code: "ja",
  },
];

const WebLanguage: React.FC<Props> = ({ language }) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [selected, setSelected] = useState<IWebLanguage>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useClickOutside(wrapperRef, () => setIsOpen(false));

  const onChangeLanguage = useCallback((langCode: string) => {
    i18n.changeLanguage(langCode);
  }, []);

  useEffect(() => {
    const selectedLang = LANGUAGE_CODES.find(
      (lang) => lang.code === i18n.language
    );
    if (selectedLang) setSelected(selectedLang);
  }, []);

  const handleSubmitData = useCallback(
    (data: IWebLanguage) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        onChangeLanguage(data.code);
      }, 500);

      return () => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
      };
    },
    [onChangeLanguage]
  );

  const handleOnShowOptions = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const renderLanguageItems = useCallback(() => {
    const addOrRemove = (lang: IWebLanguage) => {
      setIsOpen(false);
      setSelected(lang);
      handleSubmitData(lang);
    };

    return LANGUAGE_CODES?.map((language) => {
      return (
        <LangItem key={language.code}>
          <FormControlLabel
            label={
              <Typography
                color="inherit"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              >
                {language.name}
              </Typography>
            }
            control={
              <Radio
                key={1}
                checked={language.code === selected?.code}
                onClick={() => addOrRemove(language)}
                value={language.code}
                name="radio-buttons-group"
                sx={{
                  color:
                    language.code === selected?.code
                      ? MAIN_STYLES.mainPurple
                      : "default",
                  "&.Mui-checked": {
                    color:
                      language.code === selected?.code
                        ? MAIN_STYLES.mainPurple
                        : "default",
                  },
                }}
              />
            }
          />
        </LangItem>
      );
    });
  }, [handleSubmitData, selected]);

  return (
    <SelectContainer ref={wrapperRef}>
      <ContentContainer onClick={handleOnShowOptions}>
        <View style={{ flex: 1 }}>
          <Content>{selected?.name}</Content>
        </View>
        {isOpen ? (
          <ArrowDropUp sx={{ color: "rgba(34, 51, 84, 0.5)" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "rgba(34, 51, 84, 0.5)" }} />
        )}
      </ContentContainer>
      {isOpen && (
        <SelectOptionContainer>
          <SelectOptionInner>
            <View
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => {}}
            >
              <Title>전체</Title>
            </View>
            {renderLanguageItems()}
          </SelectOptionInner>
        </SelectOptionContainer>
      )}
    </SelectContainer>
  );
};

export default memo(WebLanguage);
