import { MAIN_STYLES } from "@constants";
import { Button, Dialog, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px;
  }
`;

export const CheckIconWrapper = styled("div")`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f5e4ff;
`;

export const ConfirmButton = styled(Button)`
  border-radius: 6px;
  background-color: ${MAIN_STYLES.mainPurple};
  padding: 10px 0;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
  width: 210px;
  &:hover {
    background-color: ${MAIN_STYLES.mainPurple};
  }
`;
