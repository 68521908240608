import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Grid, Typography, styled } from "@mui/material";

import { IGenericItem } from "@models";

interface Props {
  data: IGenericItem[];
  defaultValue?: string;
  mappingField?: string;
  containerStyles?: React.CSSProperties;
  onChange?: (value: any) => void;
}

export const OptionWrapper = styled("div")({
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  padding: "9px 12px",
  background: "#EEEEEE",
  borderRadius: "8px",
  margin: "10px 0px",
  opacity: 0.6,
  "&.active": {
    background: "#961BED",
    color: "white",
    opacity: 1,
  },
});

const OptionLabel = styled("div")({
  color: "white",
  fontSize: "13px",
  fontWeight: 700,
  lineHeight: "22px",
  wordWrap: "break-word",
  background: "#961BED",
  height: "24px",
  width: "24px",
  textAlign: "center",
  alignSelf: "center",
  borderRadius: "8px",
  "&.active": {
    color: "#961BED",
    background: "white",
  },
});

export const OptionName = styled(Typography)({
  color: "#111111",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "14px",
  alignSelf: "center",
  padding: "0 10px",
  "&.active": {
    color: "white",
  },
});

const FuncComponent: React.FC<Props> = ({
  data,
  containerStyles,
  mappingField = "name",
  onChange,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string | undefined>();

  const handleOnChange = useCallback(
    (item: any) => {
      onChange &&
        onChange(
          item[mappingField] !== "전체" ? item[mappingField] : undefined
        );
      setSelected(item[mappingField]);
    },
    [mappingField, onChange]
  );

  const renderItems = useMemo(
    () =>
      data.map((item: any, index) => {
        const itemClass = selected === item[mappingField] ? "active" : "";
        return (
          <OptionWrapper
            key={item.id}
            className={itemClass}
            onClick={() => handleOnChange(item)}
          >
            <OptionLabel className={itemClass}>
              {item.name !== "전체" ? Number(index) : "All"}
            </OptionLabel>
            <OptionName className={itemClass}>{t(`categories.${item.name}`)}</OptionName>
          </OptionWrapper>
        );
      }),
    [t, data, mappingField, selected, handleOnChange]
  );

  return (
    <Grid
      container
      sx={{
        gap: "10px",
        display: "flex",
        flexDirection: "row",
        ...containerStyles,
      }}
    >
      {renderItems}
    </Grid>
  );
};

export default FuncComponent;
