import qs from "qs";
import { apiInstance } from "@utils";
import {
  GetFavoriteReplyParams,
  IGetKeywordArgs,
  GetHotelParams,
} from "@models";

class HotelApi {
  private static URL = {
    HOTELS: "hotels",
    HOTEL_LOG: "/hotels/:id/logs",
    GET_KEYWORDS: "hotels/keywords",
    GET_KEYWORDS_VER2: "hotels/keywords-v2",
    RUN_CRAWLER: "hotels/send-task-crawler-manually",
    GET_HOTEL_LANGUAGES: "/hotels/:id/filter-languages",
  };

  static getHotels = async (params: GetHotelParams) => {
    return apiInstance
      .get(`${this.URL.HOTELS}?${qs.stringify(params)}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static getHotelLanguages = async (hotelId: number) => {
    return await apiInstance.get(
      this.URL.GET_HOTEL_LANGUAGES.replace(":id", hotelId.toString())
    );
  };

  static getHotelLog = async (hotelId: string, params: any) => {
    return await apiInstance.get(
      `${this.URL.HOTEL_LOG.replace(":id", hotelId)}?${qs.stringify(params)}`
    );
  };

  static updateHotel = async (hotelId: string, data: any) => {
    return await apiInstance.patch(`${this.URL.HOTELS}/${hotelId}`, data);
  };

  static getHotel = async (hotelId: string) => {
    try {
      const response = await apiInstance.get(`${this.URL.HOTELS}/${hotelId}`);
      return response;
    } catch (error) {
      return error;
    }
  };

  static runReCrawler = async () => {
    return apiInstance.post(this.URL.RUN_CRAWLER);
  };

  static getFavoriteReplies = async (params: GetFavoriteReplyParams) => {
    try {
      const response = await apiInstance.get(
        `${this.URL.HOTELS}/review-reply-favorite?${qs.stringify(params)}`
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  static getKeywords = async (params: IGetKeywordArgs) => {
    return apiInstance.get(`${this.URL.GET_KEYWORDS}?${qs.stringify(params)}`);
  };

  static getKeywordsVer2 = async (params: IGetKeywordArgs) => {
    return apiInstance.get(
      `${this.URL.GET_KEYWORDS_VER2}?${qs.stringify(params)}`
    );
  };

  static deleteHotel = async (hotelId: number) => {
    return apiInstance.delete(`${this.URL.HOTELS}/${hotelId}`);
  };

  static createHotel = async (data: any) => {
    return apiInstance.post(this.URL.HOTELS, data);
  };
}

export default HotelApi;
