import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import enTranslation from "../i18n/en.json";
import jaTranslation from "../i18n/ja.json";
import koTranslation from "../i18n/ko.json";

// const getCurrentHost =
  // process.env.REACT_APP_ENV === "PRODUCTION"
  // ? "https://app.revie.co.kr"
  // : "http://localhost:3000";
// const getCurrentHost = "http://localhost:3000";
// const getCurrentHost = "https://revie-frontend.vercel.app";

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "ko",
    lng: "ko",
    interpolation: {
      escapeValue: false,
    },
    // backend: {
    //   loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
    // },
    resources: {
      en: {
        translation: enTranslation,
      },
      ja: {
        translation: jaTranslation,
      },
      ko: {
        translation: koTranslation,
      },
    },
  });

export default i18n;