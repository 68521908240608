import axios from "axios";
import { StatusCodes } from "@constants";
import { STORAGE_KEYS, getStorageItem, resetStorageItem } from "@utils";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  // baseURL: "https://api-dev-revie.3tea.xyz/api/",
});

const refreshApisauceInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  // baseURL: "https://api-dev-revie.3tea.xyz/api/",
});

refreshApisauceInstance.interceptors.request.use((request) => {
  // console.log("===================STARTING REQUEST====================");
  // console.log("INSTANCE: refreshApisauceInstance");
  // console.log(`API URL: ${request.baseURL}${request.url}`);
  // console.log("METHOD: ", request.method);
  // console.log("PARAMS: ", request.params);
  // console.log("=======================================================");
  return request;
});

apiInstance.interceptors.request.use((request) => {
  // console.log("===================STARTING REQUEST====================");
  // console.log(`API URL: ${request.baseURL}${request.url}`);
  // console.log("METHOD: ", request.method);
  // console.log("PARAMS: ", request.params);
  // console.log("=======================================================");
  return request;
});

apiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await getStorageItem(STORAGE_KEYS.token);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (e) => {
    return Promise.reject(e);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.error(`apiInstance interceptors error: `, error);
    const { status } = error?.response;

    if (status === StatusCodes.UNAUTHORIZED) {
      clearUserInfo();
      window.location.href = "/";
      return Promise.reject(error?.response);
    }

    return Promise.reject(error?.response);
  }
);

async function setHttpAuthorizationToken(token: string) {
  apiInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const deleteHttpAuthorizationToken = () =>
  delete apiInstance.defaults.headers.common.Authorization;

const clearUserInfo = async () => {
  resetStorageItem();
  deleteHttpAuthorizationToken();
};

const renewAccessToken = async () => {
  try {
    const refreshToken = await getStorageItem(STORAGE_KEYS.refreshToken);
    const response = await refreshApisauceInstance.post(
      `auth/refresh?token=${refreshToken}`
    );
    // return data?.data?.accessToken;
  } catch (error) {
    console.error("Error_renewAccessToken : ", error);
    return undefined;
  }
};

export {
  apiInstance,
  setHttpAuthorizationToken,
  deleteHttpAuthorizationToken,
  clearUserInfo,
  renewAccessToken,
};
