import React, { useMemo } from "react";

import { Check } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";

import BaseDialog from "./BaseDialog";
import { MAIN_STYLES } from "@constants";
import { CheckIconWrapper } from "./BaseDialog/styles";

export interface IButton {
  title: string;
  variant?: "text" | "outlined" | "contained";
  onClick: () => void;
}

interface Props {
  title: string;
  visible: boolean;
  buttons?: IButton[];
  onClose: () => void;
}

const ConfirmDialog: React.FC<Props> = ({
  visible,
  title,
  buttons = [],
  onClose,
}) => {
  const renderButtons = useMemo(() => {
    if (buttons.length === 0) {
      return (
        <Button
          onClick={onClose}
          variant="contained"
          sx={{ mt: 2, width: "50%", backgroundColor: MAIN_STYLES.mainPurple }}
        >
          확인
        </Button>
      );
    } else {
      return (
        <Grid
          style={{
            gap: 10,
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
          sx={{ mt: 2 }}
        >
          {buttons.map((button, index) => (
            <Button
              key={"btn_" + index}
              fullWidth
              style={{
                backgroundColor:
                  button?.variant === "outlined"
                    ? "white"
                    : MAIN_STYLES.mainPurple,
                color:
                  button?.variant === "outlined"
                    ? MAIN_STYLES.mainPurple
                    : "white",
                borderColor: MAIN_STYLES.mainPurple,
              }}
              onClick={button.onClick}
              variant={button.variant || "contained"}
            >
              {button.title}
            </Button>
          ))}
        </Grid>
      );
    }
  }, [buttons, onClose]);

  return (
    <BaseDialog maxWidth="xl" open={visible} onClose={onClose}>
      <Box
        p="30px"
        width="500px"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <CheckIconWrapper>
          <Check style={{ color: "white" }} />
        </CheckIconWrapper>
        <Box mt="15px">
          <Typography
            fontSize="20px"
            fontWeight={700}
            color="#111"
            textAlign="center"
            lineHeight="180%"
          >
            {title.split("\n").map((i) => {
              return <div>{i}</div>;
            })}
          </Typography>
        </Box>
        {renderButtons}
      </Box>
    </BaseDialog>
  );
};

export default ConfirmDialog;
