import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { StyledToggleButton, StyledToggleButtonGroup } from "./styles";
import { difference } from "lodash";

interface ToggleButtonsMultiSelectProps {
  defaultValues?: string[];
  priceRanges: string[];
  showHeader?: boolean;
  startIcon?: boolean;
  fontSize?: string;
  justifyContentItem?: string;
  disabledMultiple?: boolean;
  handleChange?: (value: string[]) => void;
}

const ToggleButtonsMultiSelect: React.FC<ToggleButtonsMultiSelectProps> = ({
  defaultValues = [],
  priceRanges,
  showHeader = false,
  startIcon = true,
  fontSize = "14px",
  justifyContentItem = "center",
  disabledMultiple,
  handleChange,
}) => {
  const theme = useTheme();
  const [selectedRanges, setSelectedRanges] = useState<string[]>(defaultValues);

  const handleRangeChange = (
    _: React.MouseEvent<HTMLElement>,
    newRanges: string[]
  ) => {
    setSelectedRanges((prev) => {
      if (
        disabledMultiple &&
        newRanges.length > 1 &&
        prev.length < newRanges.length
      ) {
        handleChange && handleChange(difference(newRanges, prev));
        return difference(newRanges, prev);
      }
      handleChange && handleChange(newRanges);
      return newRanges;
    });
  };

  return (
    <>
      {showHeader && (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ marginTop: "20px" }}
        >
          <Typography fontSize="10px" fontWeight={700} color="#111">
            선택 호텔
          </Typography>
          <Typography fontSize="10px" fontWeight={700} color="#111">
            {`${selectedRanges.length || 0}/${priceRanges.length}`}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: showHeader
            ? `${theme.colors.customize.purple1}`
            : "",
          borderRadius: "8px",
        }}
      >
        <StyledToggleButtonGroup
          value={selectedRanges}
          onChange={handleRangeChange}
          aria-label="price range selection"
          sx={{ justifyContent: justifyContentItem }}
        >
          {priceRanges.map((range) => (
            <StyledToggleButton
              key={range}
              value={range}
              aria-label={range}
              sx={{ fontSize: fontSize }}
            >
              {startIcon && (
                <CheckIcon
                  sx={{
                    fontSize: "20px",
                    color: selectedRanges.includes(range)
                      ? "white"
                      : `${theme.colors.customize.purple}`,
                  }}
                />
              )}
              {range}
              {startIcon === false && (
                <ClearIcon
                  sx={{
                    fontSize: "20px",
                    color: selectedRanges.includes(range)
                      ? "white"
                      : `${theme.colors.customize.purple}`,
                  }}
                />
              )}
            </StyledToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Box>
    </>
  );
};

export default ToggleButtonsMultiSelect;
