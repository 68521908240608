import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { WebLanguage } from "@components";

interface Props {
  title: string;
  canGoBack?: boolean;
}

const Container = styled(Grid)({
  width: "100%",
  display: "flex",
  padding: "10px 0px 30px 0px",
  justifyContent: "flex-start",
});

const Title = styled(Typography)({
  fontWeight: 800,
  fontSize: "26px",
  fontFamily: "Pretendard Variable, monospace",
});

const FuncComponent: React.FC<Props> = ({ title, canGoBack = false }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Grid
        flex={1}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        {canGoBack && (
          <span
            style={{ cursor: "pointer", height: "35px" }}
            onClick={() => navigate(-1)}
          >
            <KeyboardArrowLeftIcon fontSize="large" />
          </span>
        )}
        <Title>{title}</Title>
      </Grid>
      <WebLanguage />
    </Container>
  );
};

export default FuncComponent;
