import { Suspense, useCallback, useEffect, useMemo, useRef } from "react";

import { useRoutes } from "react-router-dom";
import { CssBaseline, Snackbar, Typography } from "@mui/material";
import SnackbarContent from "@mui/material/SnackbarContent";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Backdrop } from "@components";
import SuspenseLoader from "@components/SuspenseLoader";

import ThemeProvider from "@theme/ThemeProvider";
import {
  HOTEL_LANGUAGE_INFO,
  POPUP_BANNER,
  SHOW_LOADING,
  STRIP_BANNER,
  TOAST_DATA,
  USER_INFO,
} from "@states";
import { AuthRouter, UserRouter, AdminRoute } from "@routes";
import UserApi from "@services/user.api";
import HotelApi from "@services/hotel.api";
import { IBanner } from "@pages/admin/bannerManagement/types";

function App() {
  const isCalledApi = useRef(false);
  const visible = useRecoilValue(SHOW_LOADING);
  const [user, setUser] = useRecoilState(USER_INFO);
  const setToastData = useSetRecoilState(TOAST_DATA);
  const setStripBanner = useSetRecoilState(STRIP_BANNER);
  const setPopupBanner = useSetRecoilState(POPUP_BANNER);
  const setHotelLanguage = useSetRecoilState(HOTEL_LANGUAGE_INFO);
  const { open, vertical, horizontal, message } = useRecoilValue(TOAST_DATA);

  const handleClose = useCallback(() => {
    setToastData((prev) => ({
      ...prev,
      open: false,
    }));
  }, [setToastData]);

  const getData = useCallback(async () => {
    if (isCalledApi.current === true) return;
    else isCalledApi.current = true;

    if (!user) return;

    const response = await UserApi.getMyProfile();
    const { data } = response?.data;
    if (data) {
      const [hotelRes, myBannerRes] = await Promise.all([
        HotelApi.getHotelLanguages(data.hotelId),
        UserApi.getMyBanners(),
      ]);
      setHotelLanguage(hotelRes?.data?.data);
      setUser(data);

      const bannerData: IBanner[] = myBannerRes?.data?.data?.result;
      const stripBanner = bannerData?.find(
        (banner) => banner.showType === "STRIP"
      );
      const popupBanners = bannerData?.filter(
        (banner) => banner.showType === "POPUP"
      );

      if (stripBanner) setStripBanner(stripBanner);
      setPopupBanner(popupBanners);
    }
  }, [setHotelLanguage, setPopupBanner, setStripBanner, setUser, user]);

  useEffect(() => {
    getData();
  }, [getData]);

  const switchRoute = useMemo(() => {
    switch (user?.role) {
      case "USER":
        return UserRouter;

      case "ADMIN":
        return AdminRoute;

      default:
        return AuthRouter;
    }
  }, [user?.role]);

  const AppRoutes = useRoutes(switchRoute);

  return (
    <ThemeProvider>
      <CssBaseline />
      <Suspense fallback={<SuspenseLoader />}>{AppRoutes}</Suspense>
      <Snackbar
        open={open}
        key={"snackbar"}
        anchorOrigin={{
          vertical: vertical || "bottom",
          horizontal: horizontal || "center",
        }}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <SnackbarContent
          sx={{ justifyContent: "center", alignItems: "center" }}
          message={
            <Typography style={{ fontSize: "24px" }}>{message}</Typography>
          }
        />
      </Snackbar>
      <Backdrop visible={visible} />
    </ThemeProvider>
  );
}
export default App;
