import { ITextStyle } from "@models";
import {
  toPixelValue,
  getFontSize,
  getFontWeight,
  DEFAULT_FONTS,
} from "@utils";

export type TextAlign =
  | "center"
  | "end"
  | "justify"
  | "left"
  | "match-parent"
  | "right"
  | "start"
  | undefined;

export function useTextStyle(props: ITextStyle) {
  const fontSize = getFontSize(props.size ?? "md");
  const fontWeight = getFontWeight(props.weight ?? "normal");
  const textAlign: TextAlign = props?.textCenter ? "center" : undefined;
  const fontStyle = props?.fontStyle ?? "normal";
  const lineHeight = `${props?.lineHeight}px` ?? "initial";
  const textDecoration = props?.underline ? "underline" : undefined
  // let fontFamily = DEFAULT_FONTS.Regular;

  // if (fontWeight === 100) {
  //   fontFamily = DEFAULT_FONTS.Thin;
  // } else if (fontWeight === 200) {
  //   fontFamily = DEFAULT_FONTS.ExtraLight;
  // } else if (fontWeight === 300) {
  //   fontFamily = DEFAULT_FONTS.Light;
  // } else if (fontWeight === 400) {
  //   fontFamily = DEFAULT_FONTS.Regular;
  // } else if (fontWeight === 500) {
  //   fontFamily = DEFAULT_FONTS.Medium;
  // } else if (fontWeight === 600) {
  //   fontFamily = DEFAULT_FONTS.SemiBold;
  // } else if (fontWeight === 700) {
  //   fontFamily = DEFAULT_FONTS.Bold;
  // } else if (fontWeight === 800) {
  //   fontFamily = DEFAULT_FONTS.ExtraBold;
  // } else if (fontWeight === 900) {
  //   fontFamily = DEFAULT_FONTS.Black;
  // }

  return {
    color: props.color ?? "#202020",
    fontSize: toPixelValue(fontSize),
    fontWeight,
    textAlign,
    fontStyle,
    lineHeight,
    textDecoration
  };
}
