import React from "react";

import { v4 as uuidv4 } from "uuid";
import { Box, Divider, Grid, Typography } from "@mui/material";

import BoxFlex from "@components/BoxFlex";

import { MixChartTooltip } from "./types";
import { CATEGORY_COLOR } from "./constants";

export const RenderReviewInfo: React.FC<{ label: string; amount: string }> = ({
  label,
  amount,
}) => {
  return (
    <Box sx={{ width: "130px", textAlign: "center", padding: "20px 0px" }}>
      <Typography
        sx={{
          color: "black",
          fontSize: "14px",
          paddingBottom: "10px",
        }}
      >
        {label}
      </Typography>
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
        {amount}
      </Typography>
    </Box>
  );
};

const TooltipChart: React.FC<MixChartTooltip> = ({
  label,
  totalReview,
  averageRating,
  positivity,
  categories,
}) => {
  return (
    <Box sx={{ width: "450px" }}>
      <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
        {label}
      </Typography>
      <BoxFlex
        sx={{
          margin: "30px 0px",
          background: "#FAF3FE",
          borderRadius: "12px",
        }}
      >
        <RenderReviewInfo label="총 리뷰" amount={`${totalReview}개`} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <RenderReviewInfo label="평균 평점" amount={`${averageRating}점`} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <RenderReviewInfo label="긍정도" amount={`${positivity.toFixed(0)}%`} />
      </BoxFlex>
      <Typography
        sx={{ fontSize: "16px", fontWeight: 700, marginBottom: "15px" }}
      >
        카테고리별 언급량
      </Typography>
      <Grid container>
        {categories.map((category) => (
          <Grid
            xs={6}
            key={uuidv4()}
            sx={{ marginBottom: "15px", padding: "0px 10px" }}
          >
            <BoxFlex>
              <BoxFlex>
                <Box
                  sx={{
                    alignSelf: "center",
                    background:
                      CATEGORY_COLOR[
                        category.name as keyof typeof CATEGORY_COLOR
                      ],
                    height: "12px",
                    width: "12px",
                  }}
                />
                <Typography
                  sx={{
                    padding: "0px 10px",
                    fontSize: "14px",
                  }}
                >
                  {category.name}
                </Typography>
              </BoxFlex>
              <Typography sx={{ padding: "0px 10px", fontSize: "14px" }}>
                {category.value}
              </Typography>
            </BoxFlex>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TooltipChart;
