export enum STORAGE_KEYS {
  user = "user",
  token = "token",
  refreshToken = "refreshToken",
}

export const getStorageItem = (key: STORAGE_KEYS) => {
  const savedValue = localStorage.getItem(key);
  return savedValue !== null ? JSON.parse(savedValue) : null;
};

export const setStorageItem = (key: STORAGE_KEYS, value: any) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageItem = (key: STORAGE_KEYS) => {
  return localStorage.removeItem(key);
};

export const resetStorageItem = () => {
  return localStorage.clear();
};

export const localStorageEffect =
  (key: STORAGE_KEYS) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null ) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
