import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import ReplayIcon from "@mui/icons-material/Replay";
import { Button, Grid, Typography, styled } from "@mui/material";

import WebLanguage from "@components/WebLanguage";

import HotelApi from "@services/hotel.api";
import { SHOW_LOADING, TOAST_DATA, USER_INFO } from "@states";

interface Props {
  title: string;
  isScrolled?: boolean;
  showCrawlButton?: boolean;
}

const Container = styled(Grid)({
  width: "100%",
  display: "flex",
  padding: "10px 0px 30px 0px",
  justifyContent: "flex-start",
});

const Title = styled(Typography)({
  fontWeight: 800,
  fontSize: "26px",
  fontFamily: "Pretendard Variable, monospace",
});

const moment = require("moment");


export const ButtonToggle = styled(Button)({
  mt: "4px",
  height: "35px",
  fontWeight: 700,
  color: "#999999",
  fontSize: "14px",
  margin: "0px 5px",
  alignSelf: "center",
  padding: "8px 12px",
  borderRadius: "6px",
  backgroundColor: "#fff",
  border: "1px #999999 solid",
  fontFamily: "Pretendard Variable, monospace",
});

const user_id = 1;

type CrawlingType = "active" | "awaiting" | "refreshing";

const FuncComponent: React.FC<Props> = ({
  title,
  isScrolled = false,
  showCrawlButton = true,
}) => {
  const intervalId = useRef<any>();
  const { t } = useTranslation();

  const refreshContent = t("sidebar_menu.refresh");

  const setToastData = useSetRecoilState(TOAST_DATA);
  const setShowLoading = useSetRecoilState(SHOW_LOADING);
  const [buttonContent, setButtonContent] = useState("새로고침");
  const [crawlingStatus, setCrawlingStatus] = useState<CrawlingType>("active");

  function calculateTimeDifference(startTime: number) {
    const currentTime = Date.now();
    const differenceInMilliseconds = currentTime - startTime;
    return differenceInMilliseconds / (1000 * 60);
  }

  const updateButtonContent = useCallback(
    (state: CrawlingType) => {
      const startTime = localStorage.getItem(`crawlingTime_${user_id}`);
      if (!startTime) return;

      const timeDifference = calculateTimeDifference(parseInt(startTime));
      const countdownTimer = state === "refreshing" ? 5 : 35;

      let remainingMinutes = Math.floor(countdownTimer - timeDifference);
      let remainingSeconds =
        remainingMinutes >= 0
          ? Math.round(
              (countdownTimer - timeDifference - remainingMinutes) * 60
            )
          : 0;

      // Check if remainingSeconds is 60 and adjust accordingly
      if (remainingSeconds === 60) {
        remainingMinutes += 1;
        remainingSeconds = 0;
      }

      if (remainingMinutes <= 0 && remainingSeconds <= 0) {
        clearInterval(intervalId.current);
        intervalId.current = null;

        if (state === "refreshing") {
          window.location.reload();
        } else {
          setButtonContent(refreshContent);
          setCrawlingStatus("active");
        }
        return;
      }
      const textContent = `${String(remainingMinutes).padStart(
        2,
        "0"
      )}:${String(remainingSeconds).padStart(2, "0")}`;
      setButtonContent(textContent);
    },
    [refreshContent]
  );

  const checkCrawlingButtonState = useCallback(() => {
    const startTime = localStorage.getItem(`crawlingTime_${user_id}`);

    if (!startTime) {
      return setCrawlingStatus("active");
    }

    const timeDifference = calculateTimeDifference(parseInt(startTime));
    if (timeDifference <= 5) {
      updateButtonContent("refreshing");

      if (!intervalId.current) {
        intervalId.current = setInterval(() => {
          updateButtonContent("refreshing");
        }, 1000);
      }

      return setCrawlingStatus("refreshing");
    } else if (timeDifference <= 35) {
      updateButtonContent("awaiting");

      if (!intervalId.current) {
        intervalId.current = setInterval(() => {
          updateButtonContent("awaiting");
        }, 1000);
      }

      return setCrawlingStatus("awaiting");
    }
    return setCrawlingStatus("active");
  }, [updateButtonContent]);

  useEffect(() => {
    checkCrawlingButtonState();
  }, [checkCrawlingButtonState]);

  const refreshData = useCallback(async () => {
    try {
      setShowLoading(true);
      await HotelApi.runReCrawler();

      localStorage.setItem("crawlingTime_" + user_id, Date.now().toString());
      setTimeout(() => {
        checkCrawlingButtonState();
      }, 0);
    } catch (error) {
      console.error("refreshData_error: >>>", error);
      setToastData((prev) => ({
        ...prev,
        open: true,
        message: "Something went wrong! Please contact to admin.",
      }));
    } finally {
      setShowLoading(false);
    }
  }, [checkCrawlingButtonState, setShowLoading, setToastData]);
  const user = useRecoilValue(USER_INFO);
  const renderButton = useMemo(() => {
    const nowDate = moment();


    if (user?.email=='help@heroworks.co.kr' && (nowDate.isBetween("2024-10-27", "2024-11-01")))
        return <></>;

    if (!showCrawlButton) return <></>;
    switch (crawlingStatus) {
      case "active":
        return (
          <ButtonToggle
            variant="outlined"
            style={{ color: "black", borderColor: "black" }}
            onClick={refreshData}
          >
            <ReplayIcon fontSize="small" sx={{ marginRight: "5px" }} />
            {refreshContent}
          </ButtonToggle>
        );

      case "refreshing":
        return (
          <ButtonToggle variant="outlined" style={{ color: "#961BED" }}>
            <ReplayIcon
              fontSize="small"
              sx={{ marginRight: "5px", color: "#961BED" }}
            />
            {buttonContent}
          </ButtonToggle>
        );

      default:
        return (
          <ButtonToggle variant="outlined">
            <ReplayIcon fontSize="small" sx={{ marginRight: "5px" }} />
            {buttonContent}
          </ButtonToggle>
        );
    }
  }, [
    buttonContent,
    refreshContent,
    crawlingStatus,
    showCrawlButton,
    refreshData,
  ]);

  return (
    <Container
      style={
        isScrolled
          ? {
              backgroundColor: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              boxShadow: "0 -15px 15px -15px rgba(0,0,0,0.2)",
              background: "linear-gradient(#f5f5f5 15%, transparent)",
            }
          : undefined
      }
    >
      <Grid flex={1}>
        <Title>{title}</Title>
      </Grid>
      <WebLanguage />
      {renderButton}
    </Container>
  );
};

export default FuncComponent;
