import { Tooltip, TooltipProps, tooltipClasses, styled } from "@mui/material";
import { Link } from "react-router-dom";
import LogoImg from "@assets/revie_white_1.svg";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        width:'100%',
        color: ${theme.palette.text.primary};
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    justifyContent: "flex-start",
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

function Logo() {
  return (
    <TooltipWrapper title="REVIE" arrow>
      <LogoWrapper to="/">
        <img
          src={LogoImg}
          alt="Logo"
          style={{ height: "30px", alignContent: "center" }}
        />
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
