import { lazy } from "react";
import { BaseLayout } from "@layouts";
import { RouteObject } from "react-router-dom";

const LoginPage = lazy(() => import("@pages/auth/Login"));
const SettingsPage = lazy(() => import("@pages/settings"));
const NotFoundPage = lazy(() => import("@pages/NotFound"));
const SignUpPage = lazy(() => import("@pages/auth/signUp"));
const FindAccountPage = lazy(() => import("@pages/auth/findAccount"));
const SendInquiryPage = lazy(() => import("@pages/auth/sendInquiry"));
const ForgetPasswordPage = lazy(() => import("@pages/auth/ForgotPassword"));
const ChangePasswordPage = lazy(() => import("@pages/auth/ChangePassword"));

const AuthRouter: RouteObject[] = [
    {
      path: "/",
      element: <BaseLayout />,
      children: [
        {
          path: "/",
          element: <LoginPage />,
        },
        {
          path: "/find-account",
          element: <FindAccountPage />,
        },
        {
          path: "/forget-password",
          element: <ForgetPasswordPage />,
        },
        {
          path: "/sign-up",
          element: <SignUpPage />,
        },
        {
          path: "/settings",
          element: <SettingsPage />,
        },
        {
          path: "/send-inquiry",
          element: <SendInquiryPage />,
        },
        {
          path: "/reset-account/:code",
          element: <ChangePasswordPage />,
        },
        {
          path: '*',
          element: <NotFoundPage />
        }
      ],
    },
  ];

export default AuthRouter;
