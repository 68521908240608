import { FC, ReactNode, useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Box, Grid, Link, alpha, lighten, useTheme } from "@mui/material";

import Sidebar from "./Sidebar";
import { HIDE_SIDEBAR, STRIP_BANNER, USER_INFO } from "@states";
interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const location = useLocation();
  const user = useRecoilValue(USER_INFO);
  const stripBanner = useRecoilValue(STRIP_BANNER);
  const [hidebar, setHideSidebar] = useRecoilState(HIDE_SIDEBAR);

  useEffect(() => {
    if (location.pathname === "/first-settings") {
      if (!user.isFirstTimeSetup) {
        setHideSidebar(true);
      }
    }
  }, [location.pathname, setHideSidebar, user.isFirstTimeSetup]);

  return (
    <Grid>
      {stripBanner && (
        <Link href={stripBanner.url} target="_blank" rel="noopener noreferrer">
          <Box
            component={"img"}
            zIndex={14}
            sx={{
              width: "100%",
              height: "80px",
              position: "fixed",
              objectFit: "cover",
            }}
            alt="배너 이미지"
            src={stripBanner?.image?.url}
          />
        </Link>
      )}
      <Grid className="fixed-width-container" mt={stripBanner ? "80px" : ""}>
        <Box
          sx={{
            flex: 1,
            height: "100%",
            ".MuiPageTitle-wrapper": {
              background:
                theme.palette.mode === "dark"
                  ? theme.colors.alpha.trueWhite[5]
                  : theme.colors.alpha.white[50],
              marginBottom: `${theme.spacing(4)}`,
              boxShadow:
                theme.palette.mode === "dark"
                  ? `0 1px 0 ${alpha(
                      lighten(theme.colors.primary.main, 0.7),
                      0.15
                    )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                  : `0px 2px 4px -3px ${alpha(
                      theme.colors.alpha.black[100],
                      0.1
                    )}, 0px 5px 12px -4px ${alpha(
                      theme.colors.alpha.black[100],
                      0.05
                    )}`,
            },
          }}
        >
          {!hidebar && <Sidebar />}
          <Box
            sx={
              !hidebar
                ? {
                    flex: 1,
                    zIndex: 5,
                    pt: `30px`,
                    display: "block",
                    position: "relative",
                    ml: `${theme.sidebar.width}`,
                  }
                : undefined
            }
          >
            <Box display="block">
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SidebarLayout;
