import * as React from "react";
import { warning_circle } from "@assets";
import { useTranslation } from "react-i18next";
import { Tooltip, Typography } from "@mui/material";

const NotEnoughData: React.FC = () => {
    const { t } = useTranslation();

  return (
    <Tooltip title={t("supplier_table.lack_of_data_title")}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={warning_circle}
          alt="warning_circle"
          style={{ marginRight: "3px" }}
        />
        <Typography
          style={{
            color: "#999999",
            fontSize: "14px",
            lineHeight: "14px",
          }}
        >
          {t("supplier_table.lack_of_data_content")}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default React.memo(NotEnoughData);
