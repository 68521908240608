import { getFontSize, toPixelValue } from "@utils";
import styled from "styled-components";
import { Text } from "../Text";

export const BackgroundScreen = styled.div`
  background-color: ${(p) => p.theme.background};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  overflow: auto;
  min-width: 1440px;
`;

export const FullScreen = styled.div`
  flex: 1;
`;

export const FullScreenCenter = styled.div<{
  bgcolor?: string;
  opacity?: number;
}>`
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: auto;
  ${(p) => p.opacity && `opacity: ${p.opacity};`}
  ${(p) => p.bgcolor && `background: ${p.bgcolor};`}
`;

export const BackgroundImage = styled.div<{ src?: string | null }>`
  flex: 1;
  background-image: ${(p) =>
    p.src !== "default" ? `url(${p.src})` : undefined};
  background-position: center;
  background-size: cover;
`;

export const BackgroundImageCenter = styled.div<{ src?: string | null }>`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-image: ${(p) =>
    p.src !== "default" ? `url(${p.src})` : undefined};
  background-position: center;
  background-size: cover;
`;

export const MainView = styled.div`
  flex: 1;
  flex-direction: row;
`;

export const LeftMenuView = styled.div`
  flex: 1;
  padding: ${(p) => p.theme.padding};
  padding-right: 0;
  min-width: 250px;
`;

export const LeftMenuItemView = styled.div`
  flex: 1;
  border: 1px solid ${(p) => p.theme.separator};
  border-radius: ${(p) => p.theme.borderRadius};
  overflow: hidden;
  background-color: ${(p) => p.theme.opacityColor};
`;

export const ScrollView = styled.div<{
  aspectRatio?: number;
  isRow?: boolean;
  border?: boolean;
}>`
  flex: 1;
  aspect-ratio: ${(p) => p.aspectRatio};

  flex-wrap: ${(p) => (p.isRow ? "wrap" : "none")};
  flex-direction: ${(p) => (p.isRow ? "row" : "column")};
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.separator};
    border-radius: 10px;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }
`;

export const ControllerView = styled.div`
  flex: 1;
  flex-direction: row;
`;

export const CenterRowView = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const View = styled.div``;

export const DefaultView = styled.div`
  flex: 1;
  padding: ${(p) => p.theme.padding};
  cursor: default;
  display: flex;
`;

export const SecondView = styled.div<{ border: boolean; width?: number }>`
  flex: 1;
  background: ${(p) => p.theme.background};
  border-radius: ${(p) => p.theme.borderRadius};
  border: ${(p) => (p.border ? `1px solid ${p.theme.separator}` : "none")};
  overflow: hidden;
  padding: ${(p) => p.theme.padding};
  ${(p) => p.width && `width: ${p.width}px;`}
`;

export const InfoContainer = styled.div`
  flex: 1;
  padding: ${(p) => p.theme.padding};
  aspect-ratio: 6/1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const InfoContents = styled.div`
  flex: 1;
  background-color: ${(p) => p.theme.opacityColor};
  border: 1px solid ${(p) => p.theme.separator};
  border-radius: ${(p) => p.theme.borderRadius};
  padding: ${(p) => p.theme.padding};
`;

export const BorderView = styled.div`
  flex: 1;
  border: 1px solid ${(p) => p.theme.separator};
  border-radius: ${(p) => p.theme.borderRadius};
  overflow: hidden;
`;

export const ListContainer = styled.div`
  border-right: 1px solid ${(p) => p.theme.separator};
  padding: ${(p) => p.theme.padding};
  padding-right: 0;
`;

export const ListView = styled.div`
  flex: 1;
  border-radius: ${(p) => p.theme.borderRadius};
  background-color: ${(p) => p.theme.opacityColor};
  padding: ${(p) => p.theme.padding};
  border: 1px solid ${(p) => p.theme.separator};
`;

export const ListBorder = styled.table<{ aspectRatio: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  aspect-ratio: ${(p) => p.aspectRatio};
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: ${(p) => p.theme.padding};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.separator};
    border-radius: 10px;
    background-clip: padding-box;
    border: ${(p) => p.theme.doubleHalfPadding} solid transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const ItemHeaderContainer = styled.tr`
  flex-direction: row;
  display: flex;
`;

export const ItemHeaderContents = styled.th<{
  flex?: number;
  sortAble?: boolean;
}>`
  flex: ${(p) => p.flex ?? 1};
  cursor: ${(p) => (p.sortAble ? "pointer" : "default")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.padding};
`;

export const ItemBodyContainer = styled.tbody``;

export const ItemContainer = styled.tr<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: ${(p) => (p.isSelected ? p.theme.primary : undefined)};
  color: ${(p) => (p.isSelected ? "white" : p.theme.secondaryLabel)};

  &:hover {
    background-color: ${(p) => p.theme.primary};
    color: white;
  }
`;

export const ItemContents = styled.td`
  flex: 1;
  flex-direction: row;
  padding: ${(p) => p.theme.padding};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-size: ${`${getFontSize("xs")}px`};
  color: ${(p) => p.color};
`;

export const EditorView = styled.div`
  background-color: ${(p) => p.theme.secondarySystemGroupedBackground};
  border-radius: ${(p) => p.theme.borderRadius};
  border: 1px solid ${(p) => p.theme.separator};
  min-width: 300px;
  overflow-y: auto;
`;

export const EditorScrollView = styled.div`
  flex: 1;
  padding: ${(p) => p.theme.padding};

  cursor: default;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: ${(p) => p.theme.padding};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.separator};
    border-radius: 10px;
    background-clip: padding-box;
    border: ${(p) => p.theme.doubleHalfPadding} solid transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const IntputTitleView = styled.div`
  padding-bottom: ${(p) => p.theme.halfPadding};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const IconButtonContainer = styled.div<{
  border?: boolean;
  padding?: string;
}>`
  flex-direction: row;
  cursor: pointer;
  border-radius: ${(p) => p.theme.borderRadius};
  border: ${(p) => (p.border ? `1px solid ${p.theme.separator}` : "none")};
  justify-content: flex-end;
  align-items: center;
  padding-bottom: ${(p) => p.padding ?? 0};
`;

export const InputConatiner = styled.div<{
  focused: boolean;
  padding?: number | string;
  paddingH?: number | string;
  paddingV?: number | string;
  selectbackground?: string;
}>`
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => p.selectbackground};

  padding: ${(p) =>
    p.padding !== undefined ? toPixelValue(p.padding) : p.theme.halfPadding};
  border-radius: ${(p) => p.theme.borderRadius};
  outline: ${(p) => (p.focused ? `1px solid ${p.theme.primary}` : "none")};
  z-index: 20;
  position: relative;
  border: 0.5px solid ${(p) => p.theme.separator};
  ${(p) => p.paddingH && `padding-left: ${toPixelValue(p.paddingH)};`}
  ${(p) => p.paddingH && `padding-right: ${toPixelValue(p.paddingH)};`}

  ${(p) => p.paddingV && `padding-top: ${toPixelValue(p.paddingV)};`}
  ${(p) => p.paddingV && `padding-bottom: ${toPixelValue(p.paddingV)};`}
`;

export const MenuItemView = styled.div<{
  selected?: boolean;
  padding?: string;
  margin?: string;
  applyBorderRadius?: boolean;
}>`
  flex-direction: row;
  padding: ${(p) => p.padding};
  cursor: pointer;
  background-color: ${(p) => (p.selected ? p.theme.primary : undefined)};
  align-items: center;
  border-radius: ${(p) => (p.applyBorderRadius ? "4px" : undefined)};
`;

export const HeaderContainer = styled.div`
  height: 65px;
  border-bottom: 1px solid ${(p) => p.theme.separator};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(p) => p.theme.padding};
`;

export const RowSpaceBetweenView = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const Divider = styled.div<{ width?: number; height?: number }>`
  background-color: ${(p) => p.theme.separator};
  min-width: ${(p) => (p.width ? toPixelValue(p.width) : 0)};
  min-height: ${(p) => (p.height ? toPixelValue(p.height) : 0)};
`;

export const PostBackground = styled.div`
  background-color: ${(p) => p.theme.opacityColor};
  border-radius: ${(p) => p.theme.borderRadius};
  border: 1px solid ${(p) => p.theme.separator};

  z-index: 0;
`;

export const CommentInputBorder = styled(BorderView)`
  padding: ${(p) => p.theme.padding};
  background-color: ${(p) => p.theme.tertiarySystemGroupedBackground};
`;

export const CommentInput = styled.textarea`
  border: none;
  background-color: ${(p) => p.theme.tertiarySystemGroupedBackground};
  color: ${(p) => p.theme.label};
  font-size: ${(p) => p.theme.fontSize};
  outline: none;
  padding: 0;
  color-scheme: ${(p) => p.theme.colorScheme};
  overflow: visible;
  resize: none;
  height: inherit;
  ::placeholder {
    color: ${(p) => p.theme.placeholderText};
  }
`;

export const PostMenuView = styled.div`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.padding};
  &:hover {
    background-color: ${(p) => p.theme.tertiarySystemGroupedBackground};
  }
`;

export const PostFileContainer = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${(p) => p.theme.padding};
`;

export const PointerView = styled.div`
  cursor: pointer;
`;

export const FileInputStyled = styled.input`
  display: none;
`;

export const ListHeaderContainer = styled.thead`
  position: sticky;
  top: 0;
  background-color: ${(p) => p.theme.secondarySystemGroupedBackground};
  border-radius: ${(p) => p.theme.borderRadius};
  z-index: 10;
`;

export const MultColorsText = styled(Text)<{ centertitle: boolean }>`
  flex-direction: row;
  align-items: center;
  align-items: flex-end;
  color: ${(p) => p.theme.text} !important;
  ${(p) => p.centertitle === true && "justify-content: center;"}
`;

export const LogoView = styled.div`
  aspect-ratio: 320/80;
`;

export const LoginSelectorContainer = styled.div`
  flex-direction: row;
  padding-bottom: ${(p) => p.theme.padding};
`;

export const ColumnItemView = styled.div`
  padding-bottom: ${(p) => p.theme.padding};
  :empty {
    padding: 0;
  }
  :last-child {
    padding-bottom: 0;
  }
`;

export const DateInfoContainer = styled(ColumnItemView)``;

export const DropdownViewContainer = styled.div<{ selected: boolean }>`
  outline: ${(p) => (p.selected ? `1px solid ${p.theme.primary}` : undefined)};
`;

export const SettingButtonContainer = styled(BorderView)`
  padding: ${(p) => p.theme.padding};
  background-color: ${(p) => p.theme.secondarySystemGroupedBackground};
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const OpacitySampleContainer = styled(BackgroundImage)`
  flex: 1;
  aspect-ratio: 16/9;
  border: 1px solid ${(p) => p.theme.separator};
  border-radius: ${(p) => p.theme.borderRadius};
`;

export const OpacitySampleView = styled.div`
  flex: 1;
  padding: ${(p) => p.theme.doubleHalfPadding};
`;

export const OpacitySampleContents = styled(SecondView)<{ color: string }>`
  background-color: ${(p) => p.color};
`;

export const Wrapper = styled.div<{ width: number }>`
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: auto;
  display: flex;
  position: fixed;
  max-width: 640px;
  width: ${(p) => p.width}px;
`;
